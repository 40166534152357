<template>
  <div
    class="bet-ticker-cell"
  >
    <div class="bet-outcome">
      <Icon
        :name="betOutcomeIcon"
      />
      <span>
        {{ betOutcomeText }}
      </span>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const betOutcomeOptions = ref({
      WON: { text: 'Won', icon: 'trophy' },
      LOST: { text: 'Lost', icon: 'thumbs-down' },
      VOID: { text: 'Void', icon: 'credit-card-x' },
      NOT_YET_SETTLED: { text: 'Not yet settled', icon: '' },
      CANCELLED: { text: 'Cancelled', icon: 'x' },
      CASHOUT: { text: 'Cashout', icon: 'coins-hand' },
    });

    const defaultOutcome = { text: 'Unknown', icon: '' };

    const betOutcome = computed(() => betOutcomeOptions.value[props.bet.betOutcome] || defaultOutcome);

    const betOutcomeText = computed(() => betOutcome.value.text);
    const betOutcomeIcon = computed(() => betOutcome.value.icon);

    return {
      betOutcomeText,
      betOutcomeIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.bet-outcome {
  display: flex;
  align-items: center;
  gap: 4px;
  border: 1px solid $gray500;
  padding: 0px 6px; border-radius: 50px;
}
</style>
